<template>
  <b-modal
    id="editarPermissoes"
    hide-footer
    size="xl"
    title="Editar Permissões"
  >
    <!-- PERMISSION TABLE -->
    <b-card
      class="border mt-1"
      no-body
      cols="12"
      md="6"
      lg="6"
    >

      <b-alert
        v-if="getErrors.hasMessage"
        :variant="getErrors.color"
        show
      >
        <div
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
          class="alert-body"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">
          {{ getErrors.message }}
        </div>
      </b-alert>
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon
            icon="LockIcon"
            size="18"
          />
          <span class="align-middle ml-50">Permissões</span>
        </b-card-title>
      </b-card-header>

      <validation-observer ref="permissaoEditarValidation">
        <b-table
          :items="result"
          :fields="fields"
          responsive
          striped
        >
          <template #head(nome)>
            <span class="text-primary">Outros Recursos</span>
          </template>
          <template #cell(nome)="data">
            {{ data.item.nome }}
          </template>
          <template #cell(permitir)="data">
            <b-form-input
                v-if="
                  data.item.nome == 'contas' ||
                  data.item.nome == 'tabelas' ||
                  data.item.nome == 'odontodocs' ||
                  data.item.nome == 'tarifa_boletos' ||
                  data.item.nome == 'tarifa_retiras' ||
                  data.item.nome == 'tarifa_nfe' ||
                  data.item.nome == 'tarifa_espaco_upload' ||
                  data.item.nome == 'espaco_upload'"
                v-model="data.item.permitir"
            />
            <b-form-checkbox v-else v-model="data.item.permitir" />
          </template>


        </b-table>

      </validation-observer>
    </b-card>
    <!-- BOTAO CADASTRAR -->
    <b-row class="my-2">
      <b-col>
        <b-button
          variant="primary"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :disabled="submit"
          @click="gravar"
        >
          <b-spinner
            v-if="spinner"
            small
          />
          <span v-if="spinner">Aguarde...</span>
          <span v-else>Gravar Alterações</span>
        </b-button>

        <b-button
          variant="outline-secondary"
          @click="$bvModal.hide('editarPermissoes')"
        >
          Fechar
        </b-button>
      </b-col>
    </b-row>

  </b-modal>
</template>

<script>

import {
  BModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BTable,
  BFormCheckbox,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BAlert,
  BFormInput,

} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {

  components: {
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BTable,
    BFormCheckbox,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BAlert,
	  BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ValidationProvider,
    ValidationObserver,
  },
  props: ['form'],

  data() {
    return {
      getErrors: {
        hasMessage: false,
        color: 'danger',
        message: '',
        messageArray: '',
      },
      spinner: false,
      submit: false,
      spinnerCep: false,
      errorsCep: false,
      errors: false,
      outros: [
        { nome: 'colaboradores', permitir: false },
        { nome: 'prestadores', permitir: false },
        { nome: 'entregadores', permitir: false },
        { nome: 'imagens', permitir: false },
        { nome: 'modulo_producao', permitir: false },
        { nome: 'contas', permitir: 1 },
        { nome: 'cheques', permitir: false },
        { nome: 'relatorio_financeiro', permitir: false },
        { nome: 'relatorio_producao', permitir: false },
        { nome: 'customizacao', permitir: false },
        { nome: 'odontodocs', permitir: false },
        { nome: 'entrega_automatizada', permitir: false },
        { nome: 'conciliacao', permitir: false },
        { nome: 'cobranca_automatica', permitir: false },
        { nome: 'xml', permitir: false },
        { nome: 'etapas', permitir: false },
        { nome: 'tabelas', permitir: 5 },
        { nome: 'tarifa_boletos', permitir: 5.50 },
        { nome: 'tarifa_retiras', permitir: 5.50 },
        { nome: 'tarifa_nfe', permitir: 4.00 },
        { nome: 'tarifa_espaco_upload', permitir: 99.90 },
        { nome: 'kanban', permitir: false },
        { nome: 'espaco_upload', permitir: 20 },
      ],
      fields: [
        { key: 'nome', label: 'Módulo Cadastros' },
        { key: 'permitir', label: 'PERMITIR', sortable: false },
      ],
    }
  },

  computed: {
    result() {
      return this.form.permissoes !== undefined && this.form.permissoes !== null ? this.form.permissoes : this.outros
    },
  },
  methods: {
    gravar() {
      this.$refs.permissaoEditarValidation.validate().then(success => {
        if (success) {
          this.$http
            .put(`adm/assinaturas/permissoes/${this.form.id}`, { permissoes: JSON.stringify(this.result) })
            .then(() => {
              this.spinner = true
              this.submit = true
              this.getErrors.hasMessage = ''

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Permissão alterada com Sucesso!',
                  icon: 'InfoIcon',
                  variant: 'success',
                },
              })

              this.$bvModal.hide('editarPermissoes')
              this.$store.dispatch('assinaturas/listaAssinaturas')
            })
            .catch(error => {
              const res = this.$responseMessages.getMessage(error, null)
              this.getErrors = res
            })
            .finally(() => {
              this.spinner = false
              this.submit = false
            })
        }
      })
    },
  },

}

</script>
