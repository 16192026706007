<template>
  <b-row>
    <b-col
      md="6"
      sm="4"
      class="my-1"
    >
      <b-button
        variant="gradient-success"
        @click="$bvModal.show('cadastrarAssinatura')"
      >
        <feather-icon
          class="mr-50"
          icon="UserPlusIcon"
        />
        <span class="align-middle">Adicionar Assinatura</span>
      </b-button>
    </b-col>

    <b-col
      md="6"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label-cols-sm="3"
        label-align-sm="right"
        label-size="lg"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="filter"
            type="search"
            placeholder="Procurar"
          />
          <b-input-group-append>
            <b-button
              :disabled="!filter"
              @click="filter = ''"
            >
              Limpar
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :items="assinaturas"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #cell(valor_mensal)="data">
          {{ data.item.valor_mensal | formataMoeda }}
        </template>
        <template #cell(valor_semestral)="data">
          {{ data.item.valor_semestral | formataMoeda }}
        </template>
        <template #cell(valor_anual)="data">
          {{ data.item.valor_anual | formataMoeda }}
        </template>
        <template #row-details="row">
          <b-card>
            <div class="d-flex mb-2">
              <feather-icon class="text-success" icon="UserIcon" size="19" />
              <h4 class="mb-0 ml-50 text-success">
                Usuários
              </h4>
            </div>
            <b-row class="mb-2">
              <b-col class="mb-1" md="4">
                <strong>Mensal : </strong>R$ {{ row.item.usuario_mensal | formataMoeda }}
              </b-col>
              <b-col class="mb-1" md="4">
                <strong>Semestral : </strong>R$ {{ row.item.usuario_semestral | formataMoeda }}
              </b-col>
              <b-col class="mb-1" md="4">
                <strong>Anual :  </strong
                >R$ {{ row.item.usuario_anual | formataMoeda }}
              </b-col>
            </b-row>

            <hr />
            <div class="d-flex mb-2">
              <feather-icon class="text-success" icon="PercentIcon" size="19" />
              <h4 class="mb-0 ml-50 text-success">
                Descontos
              </h4>
            </div>
            <b-row class="mb-2">
              <b-col class="mb-1" md="4">
                <strong>Mensal : </strong>% {{ row.item.desconto_mensal }}
              </b-col>
              <b-col class="mb-1" md="4">
                <strong>Semestral : </strong>% {{ row.item.desconto_semestral }}
              </b-col>
              <b-col class="mb-1" md="4">
                <strong>Anual :  </strong
                >% {{ row.item.desconto_anual }}
              </b-col>
            </b-row>

            <hr />
            <b-row>
              <b-col>
                <div class="d-flex mb-2">
              <feather-icon class="text-success" icon="ListIcon" size="19" />
              <h4 class="mb-0 ml-50 text-success">
                Módulos
              </h4>
            </div>
                <ul class="pl-2">
                  <li v-for="mod, index in JSON.parse(row.item.modulos)" :key="index"> {{ mod.modulo_nome }} </li>
                </ul>
              </b-col>
            </b-row>
            <b-button
              size="sm"
              variant="outline-secondary"
              @click="row.toggleDetails"
            >
              Fechar Detalhes
            </b-button>
          </b-card>
        </template>
        <template #cell(opcoes)="data">
          <feather-icon
            v-model="data.detailsShowing"
            class="mr-50"
            :class="data.detailsShowing ? 'text-primary' : 'text-default'"
            icon="EyeIcon"
            size="16"
            @click="data.toggleDetails"
          />
          <feather-icon
            class="mr-50"
            icon="Edit2Icon"
            size="16"
            @click="editarAssinatura(data.item)"
          />
          <feather-icon
            class="mr-50"
            icon="LockIcon"
            size="16"
            @click="editarPermissoes(data.item, data.index)"
          />
          <feather-icon
            class="mr-50 text-danger"
            icon="Trash2Icon"
            size="16"
            @click="excluirAssinatura(data.item.id, data.index)"
          />
        </template>
      </b-table>
    </b-col>

    <ModalCadastrarAssinatura />
    <ModalEditarAssinatura :form="editar" />
    <ModalEditarPermissoes :form="permissoes" />
  </b-row>
</template>

<script>
import {
  BTable,
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  VBModal,
} from 'bootstrap-vue'
import { mapState } from 'vuex'
// eslint-disable-next-line import/extensions
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import ModalCadastrarAssinatura from './CadastroAssinaturas.vue'
import ModalEditarAssinatura from './ModalEditarAssinatura.vue'
import ModalEditarPermissoes from './ModalEditarPermissoes.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    ModalCadastrarAssinatura,
    ModalEditarAssinatura,
    ModalEditarPermissoes,
  },
  directives: {
    'b-modal': VBModal,
  },

  data() {
    return {
      editar: [],
      permissoes: [],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      boxTwo: '',
      fields: [
        { key: 'nome', label: 'Nome', sortable: true },
        { key: 'subtitulo', label: 'Subtítulo', sortable: true },
        { key: 'valor_mensal', label: 'Valor Mensal', sortable: false },
        { key: 'valor_semestral', label: 'Valor Semestral', sortable: false },
        { key: 'valor_anual', label: 'Valor Anual', sortable: false },
        { key: 'usuarios', label: 'Usuários', sortable: false },
        { key: 'opcoes', label: 'Opções', sortable: false },
      ],
    }
  },

  computed: {
    ...mapState({
      assinaturas: state => state.assinaturas.assinaturas,
    }),
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },

  mounted() {
    this.$store.dispatch('assinaturas/listaAssinaturas')
  },

  methods: {
    editarAssinatura(assinatura) {
      this.editar = {
        ...assinatura,
        modulos: JSON.parse(assinatura.modulos),
      }
      this.$bvModal.show('editarAssinatura')
    },

    editarPermissoes(assinatura) {
      this.permissoes = {
        id: assinatura.id,
        permissoes: JSON.parse(assinatura.permissoes),
      }

      this.$bvModal.show('editarPermissoes')
    },

    excluirAssinatura(id, index) {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Deseja realmente excluir essa Assinatura ?', {
          title: 'Excluir Assinatura',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(valor => {
          if (valor) {
            this.$http
              .delete(`adm/assinaturas/${id}`)
              .then(() => {
                this.$store.commit('assinaturas/DELETAR_ASSINATURAS', index)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Assinatura excluída com sucesso!',
                    icon: 'InfoIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(error => {
                this.$responseMessages.getMessage(error, null)
              })
          }
        })
    },
  },
  filters: {
    formataMoeda(valor) {
      if (!valor) return "0,00";

      let vl = parseFloat(valor);
      return vl.toLocaleString("pt-BR", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
  }
}
</script>

<style lang="scss">
</style>
