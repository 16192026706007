<template>
  <b-modal
    id="cadastrarAssinatura"
    hide-footer
    size="xl"
    title="Cadastrar Assinatura"
  >
    <b-card>
      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show>
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>
     
      <!-- Header: Personal Info -->
      <div class="d-flex">
        <feather-icon icon="UserIcon" size="19" />
        <h4 class="mb-2 ml-50">Dados da Assinatura</h4>
      </div>

    <validation-observer ref="assinaturaCadastroValidation">
      <b-form class="mt-1">
        <b-row>
          <!-- NOME -->
          <b-col cols="6">
            <b-form-group label="Nome da Assinatura *" label-for="nome">
              <validation-provider
                  #default="{ errors }"
                  name="Nome"
                  rules="required|max:30"
                >
              <b-form-input
                id="nome"
                v-model="form.nome"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- SUBTITULO -->
          <b-col cols="6">
            <b-form-group label="Subtítulo da Assinatura" label-for="subtitulo">
              <b-form-input
                id="sutitulo"
                v-model="form.subtitulo"
              />
            </b-form-group>
          </b-col>

          <!-- USUARIO MENSAL -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="Usuário Mensal" label-for="usuario_mensal">
                <b-form-input  v-model="form.usuario_mensal"/>
            </b-form-group>
          </b-col>

          <!-- VALOR MENSAL -->
          <b-col cols="12" md="3" lg="3">
            <b-form-group label="Valor Mensal" label-for="valor_mensal">
              <b-input-group
                prepend="$"
              >
                <b-form-input type="number" v-model="form.valor_mensal"/>
              </b-input-group>
            </b-form-group>
          </b-col>

           <!-- DESCONTO MENSAL -->
          <b-col cols="12" md="3" lg="3">
            <b-form-group label="Desconto Mensal" label-for="desconto_mensal">
              <b-input-group
                prepend="%"
              >
                <b-form-input type="number" v-model="form.desconto_mensal"/>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- USUARIO SEMESTRAL -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="Usuário Semestral" label-for="usuario_semestral">
                <b-form-input v-model="form.usuario_semestral"/>
            </b-form-group>
          </b-col>

          <!-- VALOR SEMESTRAL -->
          <b-col cols="12" md="3" lg="3">
            <b-form-group label="Valor Semestral" label-for="valor_semestral">
              <b-input-group
               prepend="$"
              >
                <b-form-input type="number" v-model="form.valor_semestral"/>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- DESCONTO SEMESTRAL -->
          <b-col cols="12" md="3" lg="3">
            <b-form-group label="Desconto Semestral" label-for="desconto_semestral">
              <b-input-group
                prepend="%"
              >
                <b-form-input type="number" v-model="form.desconto_semestral"/>
              </b-input-group>
            </b-form-group>
          </b-col>

           <!-- USUARIO ANUAL -->
          <b-col cols="12" md="6" lg="6">
            <b-form-group label="Usuário Anual" label-for="usuario_anual">
                <b-form-input v-model="form.usuario_anual"/>
            </b-form-group>
          </b-col>

          <!-- VALOR ANUAL -->
          <b-col cols="12" md="3" lg="3">
            <b-form-group label="Valor Anual" label-for="valor_anual">
              <b-input-group
               prepend="$"
              >
                <b-form-input type="number" v-model="form.valor_anual"/>
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- DESCONTO ANUAL -->
          <b-col cols="12" md="3" lg="3">
            <b-form-group label="Desconto Anual" label-for="desconto_anual">
              <b-input-group
                prepend="%"
              >
                <b-form-input type="number" v-model="form.desconto_anual"/>
              </b-input-group>
            </b-form-group>
          </b-col> 
        </b-row>

        
          <div v-for="(modulo, index) in modulos" :key="index">
            <b-row>
              <b-col cols="12" md="11" lg="11">
                <b-form-group label="Módulo" :label-for="`modulo_nome${index}`">
                  <validation-provider
                    #default="{ errors }"
                    :name="`Nome Módulo ${index + 1}`"
                    rules="max:300"
                  >
                    <b-form-input
                      :id="`modulo_nome${index}`"
                      v-model="modulo.modulo_nome"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="1" lg="1">
                <b-form-group label="" label-for="">
                  <b-button
                    class="mt-2"
                    style="padding: 10px"
                    variant="outline-danger"
                    @click="excluirModulo(index)"
                  >
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
        

        <b-row>
          <b-col>
            <b-button variant="gradient-success" @click="adicionaModulo">
              <feather-icon class="mr-50" icon="PlusIcon" />
              <span class="align-middle">Adicionar Módulo</span>
            </b-button>
          </b-col>
        </b-row>
        <hr />

        <!-- BOTAO CADASTRAR -->
        <b-row class="mt-2">
          <b-col>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :disabled="submit"
              @click="cadastrarAssinatura"
            >
              <b-spinner small v-if="spinner" />
              <span v-if="spinner">Aguarde...</span>
              <span v-else>Cadastrar</span>
            </b-button>

            <b-button
              variant="outline-secondary"
              @click="$bvModal.hide('cadastrarAssinatura')"
            >
              Fechar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
      </validation-observer>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormDatepicker,
  BFormRadioGroup,
  BFormCheckboxGroup,
  BButton,
  BCard,
  BInputGroupAppend,
  BInputGroup,
  BSpinner,
  BAlert,
  BFormSelect,
  BModal,
  VBToggle,
} from "bootstrap-vue";

import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Cleave from "vue-cleave-component";

// eslint-disable-next-line import/no-extraneous-dependencies
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormCheckboxGroup,
    BButton,
    BCard,
    ValidationProvider,
    ValidationObserver,
    Cleave,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    BAlert,
    BModal,
    BFormSelect,
    BFormDatepicker,
    VBToggle,
    vSelect,
  },

  directives: { "b-toggle": VBToggle },

  data() {
    return {
      required,
      email,
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      form: {
        nome: "",
        subtitulo: "",
        valor_mensal: "",
        valor_anual: "",
        desconto_mensal: "",
        desconto_anual: "",
        usuario_mensal: "",
        usuario_semestral: "",
        usuario_anual: "",
        valor_semestral: "",
        desconto_semestral: "",
      },
      modulos: [
        {
          modulo_nome: "",
        },
      ],
      spinner: false,
      submit: false,
      spinnerCep: false,
      errorsCep: false,
      errors: false,
    };
  },

  computed: {},

  methods: {
    adicionaModulo() {
      this.modulos.push({ 
        modulo_nome: "",
      });
    },

    excluirModulo(index) {
      this.modulos.splice(index, 1);
    },

    cadastrarAssinatura() {

      let payload = {
        ...this.form,
        modulos: JSON.stringify(this.modulos)

      }

      this.$refs.assinaturaCadastroValidation.validate().then((success) => {
        if (success) {
          this.spinner = true;
          this.submit = true;
          this.errors = false;

          this.$http
            .post("adm/assinaturas", payload)
            .then((res) => {
              this.$store.commit("assinaturas/ADICIONAR_ASSINATURAS", res.data);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Assinatura Cadastrada com Sucesso!",
                  icon: "InfoIcon",
                  variant: "success",
                },
              });
              this.getErrors = false;
              this.$refs.assinaturaCadastroValidation.reset();
              this.resetarCampos();
            })
            .catch((error) => {
              let res = this.$responseMessages.getMessage(error, null);
              this.getErrors = res;
            })
            .finally(() => {
              this.spinner = false;
              this.submit = false;
            });
        }
      });
    },

   

    resetarCampos() {
      this.form = {
        nome: "",
        valor_mensal: "",
        valor_anual: "",
        desconto_mensal: "",
        desconto_anual: "",
      };
      this.modulos = {
        modulo_nome: ""
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
